<template>
  <div class="columns is-multiline is-desktop is-centered">
    <!-- Cupom -->
    <div v-if="hasCupom && defaultCupom == ''" class="column is-full">
      <div class="align-field has-text-centered">
        <p>Possui um cupom?<br>Valide aqui!</p>
        <b-field custom-class="is-small align-field" position="is-centered">
          <b-input v-model="cupom" @input="formatterCupom($event)" size="is-small"></b-input>
        </b-field>
        <div class="field">
          <b-button type="is-light" @click="resetCupom()" outlined style="margin-right: 15px;">Limpar</b-button>
          <b-button type="is-primary" @click="validForm()">Validar</b-button>
        </div>
      </div>
      
    </div>

    <div v-for="(plan, index) in mapPlans" :key="plan.codigo" :class="{'column is-one-third': defaultCupom == '', 'column': defaultCupom !== ''}">
      <div class="plan-content" :class="{ 'plan-selected-rw': (plan.codigo == selected ? true : false), 'plan-noselected' : (plan.codigo != selected && alfa ? true : false), 'plan-blocked' : plan.bloqueado }">
<!-- 
        <div class="plan-details has-background-info" v-if="plan.detalhes && plan.detalhes !== ''">
          <b-button type="is-info" size="is-small" @click="showInfo(index)" >
            <b-icon pack="fas" icon="info" size="is-small"></b-icon>
          </b-button>
        </div>
 -->
        <div>
          <div class="plan-header is-primary" :class="{'plan-header-info': plan.detalhes && plan.detalhes !== ''}">
            <div class="columns is-gapless is-mobile is-centered is-vcentered">
              <div class="column">
                <h1 class="title">{{ plan.periodo }}</h1>
              </div>
              <div class="column is-narrow is-2 is-offset-1" style="margin: 0 !important;" v-if="plan.detalhes && plan.detalhes !== ''" @click="showInfo(index)">
                <b-button class="plan-details" type="is-info" size="is-small" style="float: right !important; padding: 10px;">
                  <b-icon pack="fas" icon="info"></b-icon>
                </b-button>
              </div>
            </div>
          </div>
          <div class="plan-container" @click="selectPlan(plan)">
            <div class="plan-level">
              <h2 class="plan-description" v-html="plan.descricao"></h2>
            </div>
            <div class="plan-level">
              <div class="plan-line-divisor"></div>
            </div>
            <div class="plan-level">
              <div v-if="plan.comDesconto">
                <div class="plan-desconto">
                  <span class="plan-desconto-valor">
                    R$ {{ plan.valor.toFixed(2).replace('.', ',') }}
                  </span>
                  <b-tag type="is-primary" size="is-small" class="plan-desconto-percent" rounded>
                    Desconto {{ Math.round(plan.cupom.desconto) }}%
                  </b-tag>
                </div>
                <h2 class="plan-description-sign">
                  R$ <span>{{ plan.cupom.valor.toFixed(2).replace('.', ',') }}</span>
                </h2>
              </div>
              <div v-else>
                <h2 class="plan-description-sign">
                  R$ <span>{{ plan.valor.toFixed(2).replace('.', ',') }}</span>
                </h2>
              </div>
            </div>
            <div v-if="(plan.comBonus)"  class="block-bonus-container">
              <div class="block-bonus-value has-background-info">
                <p>Bônus</p>
                <p>de jogo</p>
                <!-- <p>R$ <span>{{ Math.round(plan.cupom.valor_bonus) }}</span></p> -->
              </div>
              <div class="block-bonus-info">
                <p>R$ <span>{{ plan.cupom.valor_bonus.toFixed(2).replace('.', ',') }}</span></p>
                <p><small>O bônus de jogo só pode ser usado para inscrição em ligas. Não é possível sacar esse valor.</small></p>
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 8px;">
          <b-button type="is-light" @click="selectPlan(plan)" outlined expanded>Assine Agora!</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Planos',
  props: {
    'email': {
      type: String,
      default: () => ""
    },
    'cpf': {
      type: String,
      default: () => ""
    },
    'hasCupom': {
      type: Boolean,
      default: () => true
    },
    'defaultInfluencer': {
      type: String,
      default: () => ""
    },
    'defaultCupom': {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      planos: [],
      selected: null,
      alfa: false,
      cupom: '',
      influencer: '',
      render: false
    }
  },
  computed: {
    mapPlans() {
      return this.planos
    }
  },
  async created() {
    if (this.defaultCupom !== ""){
      this.cupom = this.defaultCupom
    }
    if (this.defaultInfluencer !== ""){
      this.influencer = this.defaultInfluencer
    }
    this.validForm()
  },
  methods: {
    showInfo(index) {
      this.$buefy.dialog.alert({
        title: "Descrição Detalhada",
        message: this.planos[index].detalhes,
        type: "is-info",
        hasIcon: true
      });
    },
    selectPlan(plan) {
      if (plan.bloqueado) {
        this.$toasted.show('Plano Bloqueado', {
          position: 'top-center',
          duration: 3000,
          type: 'error',
          className: 'toasted-class'
        })
      }
      else {
        this.selected = plan.codigo
        this.alfa = true
        this.$emit('select', plan)
      }
    },
    formatterCupom(word) {
      this.cupom = word.toUpperCase()
    },
    resetCupom() {
      this.cupom = ''
      this.validForm()
    },
    validForm() {

      let loader = this.$loading.show()

      this.$http.post(url + '/assinaturas/plans', 
      {
        cpf: this.cpf,
        email: this.email,
        cupom: this.cupom,
        influencer: this.influencer
      })
      .catch(err => {
        console.error('HTTP_ERROR')
        loader.hide()
      })
      .then(response => {
        loader.hide()
        let cupomValido = false
        // console.log(response.data.data)
        if (response.data.data) {
          if (response.data.data.length > 0) {
            cupomValido = true
            this.planos = response.data.data
          }
        }
        if (this.cupom !== '' && !cupomValido) {
          this.$toasted.show('Cupom Inválido', {
            position: 'top-center',
            duration: 3000,
            type: 'error',
            className: 'toasted-class'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 1024px) {
  .align-field {
    width: 70% !important;
  }
}
.align-field  {
  width: 30%;
  margin: 0 auto;
}
.space-bottom {
  padding-bottom: 60px;
}
.plan-level {
  margin: 1.5rem 0.5rem;
}

.block-bonus-container {
  position: relative;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #4d4d4d;
}
.block-bonus-value {
  position: absolute;
  text-align: center;
  top: -55px;
  left: 15px;
  padding: 5px;
  border-radius: 10px;
  color: white;
  transform: translate(-50%, 90%);
}
.block-bonus-value p {
  font-size: 0.8rem;
  margin: 0 !important;
}
.block-bonus-value span {
  font-weight: bolder;
  font-size: 1rem;
}
.block-bonus-info {
  font-size: 0.8rem !important;
  font-weight: normal;
  padding: 10px 7px !important;
}
.block-bonus-info p {
  margin: 0px !important;
}
.block-bonus-info p span {
  font-size: 1.3rem !important;
  font-weight: bold;
  padding: 5px 0px !important;
}
.block-bonus-info p small{
  opacity: 0.6 !important;
}

.plan-description-sign span {
  font-size: 2rem;
  font-weight: bolder !important;
}
.plan-description-sign {
  color: white !important;
  font-weight: normal !important;
  font-size: 1rem;
  margin: 0.8rem 0 !important;
}

.plan-desconto,
.plan-desconto .plan-desconto-valor,
.plan-desconto .plan-desconto-percent {
  font-weight: normal !important;
}
.plan-desconto .plan-desconto-valor {
  font-size: 0.9rem !important;
  text-decoration: line-through;
  opacity: 0.6;
}
.plan-desconto .plan-desconto-percent {
  font-size: 0.7rem !important;
  margin-left: 0.5rem;
}

.plan-description {
  margin: 0.7rem 0 !important;
  font-size: 1.2rem;
  font-weight: normal;
  color: white;
}
.plan-content {
  position: relative;
  border-radius: 7px;
  background-color: #272727;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
}
.plan-content:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  border: 2px solid rgba(0,255,204,1);
  filter: blur(0px);
}
.plan-blocked:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  -moz-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  border: 2px solid rgba(255, 43, 89, 0.75) !important;
  filter: blur(0px);
}
.plan-header {
  width: 100%;
  /* height: 30px;
  padding: 8px 5px; */
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  background: rgba(0,255,204,1);
  background: -moz-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,255,204,1)), color-stop(100%, rgba(0,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: linear-gradient(to right, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
}
.plan-header .title {
  font-size: 1.0rem !important;
  color: black !important;
  margin: 0px !important;
  line-height: auto !important;
}
.plan-container {
  padding: 10px;
  width: 100%;
  text-align: center;
}
.plan-selected-rw {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  border: 2px solid rgba(0,255,204,1);
}
.plan-noselected {
  filter: blur(1px)
}

.plan-blocked {
  z-index: 2;
  border-color: #ff3860;
  background-color: #ff38601a;
}
.blocked {
  width: 100%;
  height: 100px;
  z-index: 3;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plan-details {
  /* position: absolute; */
  right: 0px;
  padding: 5px;
  /* height: 35px; */
}

.plan-line-divisor {
  background-color: rgba(0,255,204,1);
  border: none;
  display: block;
  height: 1px;
  margin: 1rem 0;
}
</style>
