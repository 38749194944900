<template>
  <section v-if="renderPage" id="renewPage">
    <div class="influencer-bg-black">
      <div v-if="$mq == 'sm'" style="float: right; margin: 1rem 0.5rem;">
        <img src="@/assets/logotipo_green.png" alt="Escala 10" width="70px" />
      </div>

      <div class="columns is-centered is-multiline">
        <div
          class="column is-three-quarters"
          :style="$mq == 'sm' ? 'padding: 1.5em;' : ''"
        >
          <div class="columns is-multiline is-gapless is-centered">
            <div
              class="column is-half is-centered is-vcentered influencer-content"
            >
              <div class="columns is-multiline is-centered is-vcentered">
                <div class="column is-9">
                  <div class="columns is-multiline">
                    <div class="column is-full has-text-left">
                      <h1 class="title-inf texto-destaque">
                        RENOVE E GANHE!!!
                      </h1>
                      <h1 class="title-inf">Temporada 2021 Escala10!</h1>
                      <h2 class="subtitle-inf">
                        Que tal ter acesso completo ao melhor site de
                        estatísticas do Cartola FC para a temporada 2021 e ainda
                        levar um bônus para participar das ligas exclusivas do
                        Escala10!?
                      </h2>
                    </div>
                    <div
                      v-if="$mq !== 'sm'"
                      class="box-image-inf"
                      style="margin-left: 0.7em"
                      :style="{
                        'background-image':
                          'url(' + influencerData.url_foto + ')',
                      }"
                    ></div>

                    <b-field
                      v-if="$mq !== 'sm'"
                      grouped
                      group-multiline
                      :position="$mq == 'sm' ? 'is-centered' : ''"
                      style="padding: 0.7em"
                    >
                      <a
                        v-for="(contato, idx) of influencerData.contatos"
                        :key="idx"
                        :href="contato.link"
                        target="_blank"
                        style="margin: 0.25em"
                      >
                        <div class="control">
                          <b-taglist attached>
                            <b-tag type="is-primary">
                              <b-icon
                                :pack="contato.pack"
                                :icon="contato.icon"
                              ></b-icon>
                            </b-tag>
                            <b-tag type="is-dark">
                              {{ contato.user }}
                            </b-tag>
                          </b-taglist>
                        </div>
                      </a>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="column is-half is-centered is-vcentered influencer-content"
            >
              <div
                class="columns is-multiline is-gapless is-centered is-vcentered"
              >
                <div
                  class="column is-full"
                  :class="{ 'has-text-centered': $mq !== 'sm' }"
                >
                  <div
                    class="columns is-multiline is-gapless influencer-title"
                    :class="{ 'is-centered is-vcentered': $mq !== 'sm' }"
                  >
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Pontuação mínima pra valorizar!
                    </div>
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Cruzamento de Scouts!
                    </div>
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Probabilidades das Partidas!
                    </div>
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Análise de Arbitragem!
                    </div>
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Mapa de Gols e Assistências!
                    </div>
                    <div class="column is-narrow">
                      <b-icon icon="check" type="is-primary" />
                      Notícias dos clubes!
                    </div>
                    <div class="column is-full">
                      <b-icon icon="check" type="is-primary" />
                      E muito mais!
                    </div>
                    <div class="column is-full">
                      <span class="has-text-primary texto-destaque">
                        Assine o Escala10 <br />
                        e saia na frente das suas ligas!
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="column is-full has-text-centered"
                  style="margin: 0.5em 0"
                >
                  <div class="columns is-gapless is-multiline is-centered">
                    <div class="column is-8" style="margin-top: 1em;">
                      <b-carousel
                        :arrow="false"
                        :indicator="false"
                        :pause-info="false"
                      >
                        <b-carousel-item
                          v-for="(plan, index) in planos"
                          :key="plan.codigo"
                        >
                          <div class="plan-content has-background-bluedark2">
                            <div>
                              <div
                                class="plan-header is-primary"
                                :class="{
                                  'plan-header-info':
                                    plan.detalhes && plan.detalhes !== '',
                                }"
                              >
                                <div
                                  class="columns is-gapless is-mobile is-centered is-vcentered"
                                >
                                  <div class="column">
                                    <h1 class="title">{{ plan.periodo }}</h1>
                                  </div>
                                  <div
                                    class="column is-narrow is-2 is-offset-1"
                                    style="margin: 0 !important;"
                                    v-if="plan.detalhes && plan.detalhes !== ''"
                                    @click="showInfo(index)"
                                  >
                                    <b-button
                                      class="plan-details"
                                      type="is-info"
                                      size="is-small"
                                      style="float: right !important; padding: 10px;"
                                    >
                                      <b-icon pack="fas" icon="info"></b-icon>
                                    </b-button>
                                  </div>
                                </div>
                              </div>
                              <div class="plan-container">
                                <div class="plan-level">
                                  <h2
                                    class="plan-description"
                                    v-html="plan.descricao"
                                  ></h2>
                                </div>
                                <div class="plan-level">
                                  <div class="plan-line-divisor"></div>
                                </div>
                                <div class="plan-level">
                                  <div v-if="plan.comDesconto">
                                    <div class="plan-desconto">
                                      <span class="plan-desconto-valor">
                                        R$
                                        {{
                                          plan.valor
                                            .toFixed(2)
                                            .replace(".", ",")
                                        }}
                                      </span>
                                      <b-tag
                                        type="is-primary"
                                        size="is-small"
                                        class="plan-desconto-percent"
                                        rounded
                                      >
                                        Desconto
                                        {{ Math.round(plan.cupom.desconto) }}%
                                      </b-tag>
                                    </div>
                                    <h2 class="plan-description-sign">
                                      R$
                                      <span>{{
                                        plan.cupom.valor
                                          .toFixed(2)
                                          .replace(".", ",")
                                      }}</span>
                                    </h2>
                                  </div>
                                  <div v-else>
                                    <h2 class="plan-description-sign">
                                      R$
                                      <span>{{
                                        plan.valor.toFixed(2).replace(".", ",")
                                      }}</span>
                                    </h2>
                                  </div>
                                </div>
                                <div
                                  v-if="plan.comBonus"
                                  class="block-bonus-container"
                                >
                                  <div
                                    class="block-bonus-value has-background-info"
                                  >
                                    <p>Bônus</p>
                                    <p>de jogo</p>
                                  </div>
                                  <div
                                    class="block-bonus-info has-background-bluedark"
                                  >
                                    <p>
                                      R$
                                      <span>{{
                                        plan.cupom.valor_bonus
                                          .toFixed(2)
                                          .replace(".", ",")
                                      }}</span>
                                    </p>
                                    <p>
                                      <small
                                        >O bônus de jogo só pode ser usado para
                                        inscrição em ligas. Não é possível sacar
                                        esse valor.</small
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-carousel-item>
                      </b-carousel>
                    </div>

                    <div class="column is-8" style="margin-top: 1em;">
                      <router-link
                        :to="
                          '/login?action=renovacaologin&influencer=' +
                            influencerId +
                            '&cupom=' +
                            this.cupom
                        "
                      >
                        <b-button type="is-primary" expanded>
                          Eu quero!
                        </b-button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="$mq !== 'sm'"
              class="column is-full has-text-centered"
              style="position: relative;"
            >
              <b-icon
                class="pulo-icon"
                pack="fas"
                icon="chevron-down"
                type="is-primary"
                size="is-medium"
              ></b-icon>
            </div>
          </div>
        </div>

        <div
          class="column is-full is-centered is-vcentered has-background-darkzao influencer-footer"
        >
          <div class="columns is-centered has-text-white has-text-centered">
            <div class="column is-three-quarters">
              <div class="columns is-centered">
                <div class="column influencer-footer">
                  <div class="box-icon-footer">
                    <img src="@/assets/ligas-melhor.png" width="100px" />
                  </div>

                  <p class="influencer-footer-title">O MELHOR DAS SUAS LIGAS</p>
                  <p class="influencer-footer-description">
                    Para ganhar qualquer tipo de liga você precisa muito mais do
                    que sorte! O Escala 10 te permite analisar uma infinidade de
                    dados estatísticos e ter acesso a uma quantidade valiosa de
                    informações para você se destacar!
                  </p>
                </div>
                <div class="column influencer-footer">
                  <div class="box-icon-footer">
                    <img src="@/assets/ligas-estatisticas.png" width="100px" />
                  </div>

                  <p class="influencer-footer-title">
                    AS ESTATÍSTICAS MAIS COMPLETAS
                  </p>
                  <p class="influencer-footer-description">
                    Cartola sem estatísca é chute, e pensando nisso criamos o
                    Escala 10. Aqui você vai encontrar as estatísticas mais
                    valiosas e completas para te auxiliar na sua escalação, ou
                    seja, uma plataforma completa contendo informações de
                    scouts, atletas e clubes, com todas as análises
                    interligadas.
                  </p>
                </div>
                <div class="column influencer-footer">
                  <div class="box-icon-footer">
                    <img src="@/assets/ligas-noticias.png" width="100px" />
                  </div>

                  <p class="influencer-footer-title">
                    NOTÍCIAS EM PRIMEIRA MÃO
                  </p>
                  <p class="influencer-footer-description">
                    Informação é tudo! Saber quem vai jogar ou ficar de fora,
                    pode ser o diferencial da rodada! Somente no Escala 10, você
                    tem acesso as notícias em primeira mão dos setoristas de
                    cada clube!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="column is-full is-centered is-vcentered has-background-black"
        >
          <b-field
            v-if="$mq == 'sm'"
            grouped
            group-multiline
            :position="$mq == 'sm' ? 'is-centered' : ''"
            style="padding: 0.7em"
          >
            <a
              v-for="(contato, idx) of influencerData.contatos"
              :key="idx"
              :href="contato.link"
              target="_blank"
              style="margin: 0.25em"
            >
              <div class="control">
                <b-taglist attached>
                  <b-tag type="is-primary">
                    <b-icon :pack="contato.pack" :icon="contato.icon"></b-icon>
                  </b-tag>
                  <b-tag type="is-dark">
                    {{ contato.user }}
                  </b-tag>
                </b-taglist>
              </div>
            </a>
          </b-field>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Planos from "@/components/payment/Plans";

export default {
  name: "RenewPage",
  components: {
    plans: Planos,
  },
  data() {
    return {
      renderPage: false,
      influencerData: {},
      qtdAssinantes: 0,
      cupom: "",
      planos: {},
    };
  },
  computed: {
    influencerId() {
      return this.$route.params.id;
    },
  },
  async created() {
    let loader = this.$loading.show();

    try {
      ///this.influencerId = (this.$route.query.influencer) ? this.$route.query.influencer : ''
      this.cupom = this.$route.query.cupom ? this.$route.query.cupom : "";

      if (this.influencerId && this.influencerId !== "") {
        this.influencerData = await this.getInfluencerById(this.influencerId);
        this.renderPage = true;

        if (!this.influencerData) {
          this.$toasted.error("Influenciador não identificado", {
            duration: 3000,
            position: "top-center",
          });
        }

        // this.$http.get(url + '/influenciadores/' + this.influencerId)
        // .catch(err => {
        //   console.log(err);
        //   this.$toasted.error("Não foi possível carregar as informações", {
        //     duration: 3000,
        //     position: "top-center"
        //   });
        //   loader.hide()
        // })
        // .then(response => {

        //   if (response.data.data) {
        //     this.influencerData = response.data.data
        //     this.renderPage = true
        //   } else {
        //     this.$toasted.error("Influenciador não identificado", {
        //       duration: 3000,
        //       position: "top-center"
        //     });
        //   }

        //   loader.hide();
        // })

        this.planos = await this.getPlans({
          cpf: "",
          email: "",
          cupom: this.cupom,
          influencer: this.influencerId,
        });

        let cupomValido = false;
        if (this.planos) {
          if (this.planos.length > 0) {
            cupomValido = true;
            this.planos = this.planos.filter((a, i) => i < 3);
          }
        }
        if (this.cupom !== "" && !cupomValido) {
          this.$toasted.show("Cupom Inválido", {
            position: "top-center",
            duration: 3000,
            type: "error",
            className: "toasted-class",
          });
        }

        this.qtdAssinantes = await this.getQtdAssinantes();

        loader.hide();
      }
    } catch (err) {
      console.log(err);
      this.$toasted.error("Não foi possível carregar as informações", {
        duration: 3000,
        position: "top-center",
      });
      loader.hide();
    }
  },
  methods: {
    ...mapActions(["getInfluencerById", "getPlans", "getQtdAssinantes"]),
    showInfo(index) {
      this.$buefy.dialog.alert({
        title: "Descrição Detalhada",
        message: this.planos[index].detalhes,
        type: "is-info",
        hasIcon: true,
      });
    },
  },
};
</script>

<style scoped>
#renewPage {
  width: 100%;
  height: 100%;
  color: white;
  overflow-y: auto !important;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-image: url("../assets/bg_escala10.png");
  background-position: center;
  background-size: cover;
}
.influencer-bg-black {
  background-color: rgba(0, 0, 0, 0.8);
}
.influencer-sec {
  width: 100%;
  height: 100vh;
  display: table;
}
.influencer-contact a {
  font-size: 1em;
  margin: 0 10px;
}
.bg-new {
  min-height: 410px;
  max-height: 410px;
  max-width: 410px;
  min-width: 410px;
  background-image: linear-gradient(
    to right top,
    #00ffb1,
    #00ffc9,
    #00ffde,
    #00fff0,
    #00ffff
  );
  padding: 0.3em;
}
.box-image-inf {
  min-height: 350px;
  max-height: 350px;
  max-width: 350px;
  min-width: 350px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 4px solid #00ffc9;
}
.influencer-title {
  width: 100%;
  color: white;
  font-size: 0.9rem !important;
  padding: 0.5em 0;
}
.influencer-title p {
  margin: 5px 0;
}
.influencer-description {
  text-align: center;
  font-size: 0.75rem;
  padding: 15px;
}
.bt-main-11 {
  border-radius: 7px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  color: #00fffd;
  border: 2px solid #00fffd;
  font-weight: bolder;
}
.bt-main-22 {
  margin-left: 20px;
  border-radius: 7px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #00fffd;
  color: #333;
  border: 2px solid #00fffd;
  font-weight: bolder;
}
.bt-main-11:hover {
  background-color: #00fffd;
  color: #333;
}
.bt-main-22:hover {
  background-color: #00e2e2;
  border: 2px solid #00e2e2;
  color: #333;
}

@media (min-width: 342px) and (max-width: 1024px) {
  .influencer-content {
    margin: 1em 0 !important;
  }
  .influencer-sec {
    padding-top: 0px !important;
  }
}

.logo-escala10-green {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../assets/logotipo_green.png");
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}

.logo-escala10-black {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../assets/logotipo_black.png");
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}

.box-icon-footer {
  border-radius: 50%;
  padding: 1em;
  background-image: linear-gradient(
    to right top,
    #00ffb1,
    #00ffc9,
    #00ffde,
    #00fff0,
    #00ffff
  );
  width: 120px;
  height: 120px;
  position: relative;
  /* top: 10%; */
  left: 50%;
  transform: translate(-50%, -20%);
}
.influencer-footer {
  margin-top: 1.5em;
  padding: 1.5em !important;
}
.influencer-footer-title {
  padding: 10px 0 !important;
  font-size: 1em;
  font-weight: bold;
}
.influencer-footer-description {
  font-size: 0.8em;
  text-align: justify;
}
.influencer-plan-container {
  height: 110px;
  /* background-color: #272727; */
  overflow: hidden;
  cursor: default;
  border: 1px solid #00fffd;
}
.comparative-legend {
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
}
.influencer-plan-header {
  width: 100%;
  /* height: 100%; */
  padding: 10px 5px;
  color: black;
  font-size: 0.9rem;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
}
.influencer-plan-content {
  padding: 5px;
  width: 100%;
  text-align: center;
  color: white;
}
.influencer-plan-value {
  font-size: 1.2rem;
}
.influencer-plan-saleValue {
  font-size: 0.9rem;
  text-decoration: line-through;
}
.align-content-inf {
  margin: 0 auto;
  width: 70%;
}
@media (max-width: 600px) {
  .align-content-inf {
    margin: 0 auto;
    width: calc(100% - 3em);
  }
}
.brand-content {
  display: flex;
  flex-direction: row;
  min-height: 2em;
  border-radius: 4px;
  border: 1px solid #00fffd;
}
.brand-icon {
  padding: 0.5em;
  height: 100%;
  background-color: #00fffd;
  color: black;
}
.brand-name {
  padding: 0.5em;
  font-size: 18px;
  height: 100%;
}

.title-inf {
  font-size: 1.1em;
  font-weight: 800;
  text-align: left;
  margin: 1.6em 0;
  color: white;
}
.subtitle-inf {
  font-size: 0.9em !important;
  text-align: left;
  color: white;
}
.texto-destaque {
  font-weight: bold;
  font-size: 1.2rem !important;
  margin: 1em 0;
}

.block-bonus-container {
  position: relative;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #4d4d4d;
}
.block-bonus-value {
  position: absolute;
  text-align: center;
  top: -55px;
  left: 15px;
  padding: 2.5px;
  border-radius: 10px;
  color: white;
  transform: translate(-50%, 90%);
}
.block-bonus-value p {
  font-size: 0.8rem;
  margin: 0 !important;
}
.block-bonus-value span {
  font-weight: bolder;
  font-size: 1rem;
}
.block-bonus-info {
  font-size: 0.8rem !important;
  font-weight: normal;
  padding: 5px 3.5px !important;
}
.block-bonus-info p {
  margin: 0px !important;
}
.block-bonus-info p span {
  font-size: 1.3rem !important;
  font-weight: bold;
  padding: 2.5px 0px !important;
}
.block-bonus-info p small {
  opacity: 0.6 !important;
}

.plan-description-sign span {
  font-size: 2rem;
  font-weight: bolder !important;
}
.plan-description-sign {
  color: white !important;
  font-weight: normal !important;
  font-size: 1rem;
  margin: 0.8rem 0 !important;
}

.plan-desconto,
.plan-desconto .plan-desconto-valor,
.plan-desconto .plan-desconto-percent {
  font-weight: normal !important;
}
.plan-desconto .plan-desconto-valor {
  font-size: 0.9rem !important;
  text-decoration: line-through;
  opacity: 0.6;
}
.plan-desconto .plan-desconto-percent {
  font-size: 0.7rem !important;
  margin-left: 0.5rem;
}

.plan-description {
  margin: 0.7rem 0 !important;
  font-size: 1.2rem;
  font-weight: normal;
  color: white;
}
.plan-content {
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
}
.plan-content:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  border: 2px solid rgba(0, 255, 204, 1);
  filter: blur(0px);
}
.plan-blocked:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  -moz-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  border: 2px solid rgba(255, 43, 89, 0.75) !important;
  filter: blur(0px);
}
.plan-header {
  width: 100%;
  /* height: 30px; */
  padding: 3px;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
}
.plan-header .title {
  font-size: 1rem !important;
  color: black !important;
  margin: 0px !important;
  line-height: auto !important;
}
.plan-container {
  padding: 10px;
  width: 100%;
  text-align: center;
}
.plan-selected-rw {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  border: 2px solid rgba(0, 255, 204, 1);
}
.plan-noselected {
  filter: blur(1px);
}

.plan-blocked {
  z-index: 2;
  border-color: #ff3860;
  background-color: #ff38601a;
}
.blocked {
  width: 100%;
  height: 100px;
  z-index: 3;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plan-details {
  /* position: absolute; */
  right: 0px;
  padding: 5px;
  /* height: 35px; */
}

.plan-line-divisor {
  background-color: rgba(0, 255, 204, 1);
  border: none;
  display: block;
  height: 1px;
  margin: 1rem 0;
}
.pulo-icon {
  position: absolute;
  transition: all 0.2s ease-in-out;
  animation: bouncy 2s infinite ease-in-out;
  bottom: 1.8em;
}

@media (min-width: 342px) and (max-width: 1024px) {
  .pulo-icon {
    left: 40%;
  }
}
</style>
